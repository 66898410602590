import { useTheme } from "../contexts/theme/Theme.context";

const StormSvg = ({ ...props }) => {
  const { theme } = useTheme();

  return (
    <svg style={{ ...props }} viewBox="0 0 512 512" fill={theme["--svg"]}>
      <path d="M433.669,150.884h-6.713c-3.941-64.6-57.747-115.948-123.322-115.948c-34.47,0-67.121,14.3-90.485,39.434 c-11.555-5.057-23.841-7.616-36.602-7.616c-48.023,0-87.544,37.09-91.421,84.13h-6.796C35.14,150.884,0,186.023,0,229.216 s35.14,78.332,78.332,78.332h49.091V292.34H78.332c-34.807-0.001-63.125-28.318-63.125-63.125 c0-34.807,28.318-63.125,63.125-63.125h21.69v-7.604c0-42.197,34.33-76.526,76.526-76.526c12.314,0,24.093,2.855,35.012,8.487 l5.383,2.777l3.909-4.626c20.655-24.439,50.828-38.456,82.782-38.456c59.742,0,108.346,48.603,108.346,108.345v7.604h21.689 c34.807,0,63.125,28.318,63.125,63.125c0,34.807-28.318,63.125-63.125,63.125H319.579l10.84-21.154h-56.76V125.259 L198.605,292.34H147.7v15.207h44.075l-9.502,21.154h55.39l-12.742,148.366l86.867-169.52h121.883 c43.192,0,78.332-35.14,78.332-78.332C512.003,186.023,476.861,150.884,433.669,150.884z M246.695,401.224l7.535-87.731h-48.457 l2.671-5.947l50.007-111.323v90.169h47.087L246.695,401.224z" />
      <path d="M466.379,229.216h15.207c0-26.422-21.496-47.918-47.918-47.918v15.207C451.705,196.505,466.379,211.179,466.379,229.216z " />
      <path d="M381.565,158.486h15.207c0-51.356-41.781-93.137-93.137-93.137v15.207C346.605,80.556,381.565,115.516,381.565,158.486z" />
    </svg>
  );
};

export default StormSvg;
