import { useTheme } from "../contexts/theme/Theme.context";

const AvatarSvg = ({ ...props }) => {
  const { theme } = useTheme();

  return (
    <svg style={{ ...props, padding: "2px" }} viewBox="0 0 1024 1024" fill="none" opacity={0.9}>
      <path
        d="M511.66208 605.42464c132.03968 0 239.079424-170.817536 239.079424-302.857216C750.741504 170.527744 643.70176 63.488 511.66208 63.488S272.582656 170.527744 272.582656 302.567424s107.03872 302.857216 239.079424 302.857216m-172.55936 35.838976h-46.58176c-88.17664 0-159.40096 71.358464-159.40096 159.3856v79.768576c0 43.977728 35.634176 79.616 79.5904 79.616h597.90336c44.089344 0 79.5904-35.64544 79.5904-79.616v-79.7696c0-87.846912-71.366656-159.3856-159.401984-159.3856h-46.58176c-47.474688 37.433344-107.408384 59.769856-172.558336 59.769856-65.149952 0-125.083648-22.336512-172.55936-59.768832"
        fill={theme["--font"]}
      />
    </svg>
  );
};

export default AvatarSvg;
