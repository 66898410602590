import { useTheme } from "../contexts/theme/Theme.context";

const UserSvg = ({ ...props }) => {
  const { theme } = useTheme();

  return (
    <svg style={{ ...props }} viewBox="0 0 24 24" fill={theme["--svg"]}>
      <path d="M7.86872332,11.2987983 L3.81348725,13.3862352 C3.31395818,13.6433683 3,14.15809 3,14.7199144 L3,15.5 C3,16.3284353 3.67158104,17 4.50007042,17 C4.77621279,17 5.00007042,17.2238576 5.00007042,17.5 C5.00007042,17.7761424 4.77621279,18 4.50007042,18 C3.11930174,18 2,16.8807255 2,15.5 L2,14.7199144 C2,13.7835403 2.52326364,12.9256709 3.35581209,12.4971157 L6.71355663,10.7687133 C5.64186533,9.83428889 5,8.47230593 5,7 C5,4.23857625 7.23857625,2 10,2 C11.6765944,2 13.2132159,2.83194719 14.1379524,4.1925488 C14.2931758,4.42093513 14.2338655,4.73191224 14.0054791,4.88713561 C13.7770928,5.04235899 13.4661157,4.9830487 13.3108923,4.75466237 C12.5703998,3.66514623 11.3418627,3 10,3 C7.790861,3 6,4.790861 6,7 C6,8.41171278 6.73680464,9.69567406 7.92026949,10.4175913 C8.15601291,10.5613955 8.23054436,10.8690796 8.08674015,11.104823 C8.0329088,11.1930707 7.95611316,11.2587279 7.86872332,11.2987983 L7.86872332,11.2987983 Z M11.9612658,15.5668358 L7.87929558,17.4222768 C7.34380416,17.665682 7,18.1996113 7,18.7878265 L7,19.5 C7,20.3284271 7.67157288,21 8.5,21 L19.5,21 C20.3284271,21 21,20.3284271 21,19.5 L21,18.7878265 C21,18.1996113 20.6561958,17.665682 20.1207044,17.4222768 L16.0387342,15.5668358 C15.4161054,15.8452135 14.7261289,16 14,16 C13.2738711,16 12.5838946,15.8452135 11.9612658,15.5668358 L11.9612658,15.5668358 Z M10.9221582,14.9406987 C9.75209123,14.0255364 9,12.6005984 9,11 C9,8.23857625 11.2385763,6 14,6 C16.7614237,6 19,8.23857625 19,11 C19,12.6005984 18.2479088,14.0255364 17.0778418,14.9406987 L20.5345074,16.5119103 C21.4269931,16.9175857 22,17.8074678 22,18.7878265 L22,19.5 C22,20.8807119 20.8807119,22 19.5,22 L8.5,22 C7.11928813,22 6,20.8807119 6,19.5 L6,18.7878265 C6,17.8074678 6.57300693,16.9175857 7.46549264,16.5119103 L10.9221582,14.9406987 L10.9221582,14.9406987 Z M14,15 C16.209139,15 18,13.209139 18,11 C18,8.790861 16.209139,7 14,7 C11.790861,7 10,8.790861 10,11 C10,13.209139 11.790861,15 14,15 Z" />
    </svg>
  );
};

export default UserSvg;
